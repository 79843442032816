import React from "react";
import ReactDOM from "react-dom";
import "./toggle.css";
import "./DayPicker.css";
import "./hamburger.css";
import "./i18n";

import OnBoarding from "./OnBoarding";
import "./onBoarding.css";
import HttpsRedirect from "react-https-redirect";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AccessTokenProvider } from "./AccessTokenContext";

ReactDOM.render(
  <HttpsRedirect>
    <AccessTokenProvider>
      <GoogleOAuthProvider clientId="653336756076-8ibamjp707ghpvnusuhbigqbqg2n2fn6.apps.googleusercontent.com">
        <OnBoarding />
      </GoogleOAuthProvider>
    </AccessTokenProvider>

  </HttpsRedirect >,
  document.getElementById("root")
);
