import React, { createContext, useState, useEffect } from "react";

const AccessTokenContext = createContext();

export const AccessTokenProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);

  // Function to fetch access token from API
  const getAccessFcmAccessToken = async () => {
    const url =
      "https://firedefence-api.testdevurl.com/v1/barzone/get_access_token";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Fetched token:", data?.access_token);
      localStorage.setItem("@accessToken", data?.access_token);
      setAccessToken(data?.access_token);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  // UseEffect to retrieve token on initial render
  useEffect(() => {
    const fetchStoredToken = async () => {
      try {

        await getAccessFcmAccessToken();
      } catch (error) {
        console.error("Error retrieving stored token:", error);
      }
    };

    fetchStoredToken();
  }, []);

  return (
    <AccessTokenContext.Provider
      value={{ accessToken, getAccessFcmAccessToken }}
    >
      {children}
    </AccessTokenContext.Provider>
  );
};

export default AccessTokenContext;
