import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "./images/logoFull.png";
import mockup from "./images/mockupfinal.png";
import fblogo from "./images/facebook-logo.png";
import googlelogo from "./images/google-logo.png";
import applelogo from "./images/apple-logo.png";
import borderZone from "./images/borderZone.svg";
import leftAeerw from "./images/leftAeerw.png";
import Select from "react-select";

import googlePlay from "./images/googlePlay.png";
import appstore from "./images/appstore.png";
import facebook from "./images/facebook.png";
import instagram from "./images/instagram.png";
import YouTube from "./images/youtube.png";
import tiktok from "./images/tiktok.png";
import { useHistory } from "react-router-dom";
import i18n from "i18next";
import us from "./images/luagugeflag/united-states.png";
import france from "./images/luagugeflag/france.png";
import sweden from "./images/luagugeflag/sweden.png";
import germany from "./images/luagugeflag/germany.png";
import spain from "./images/luagugeflag/spain.png";
import "./landing.css";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import axios from "axios";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";

import AppleLogin from "react-apple-login";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorText: "",
      flag: false,
      facebook: false,
    };
  }

  componentDidUpdate() {
    if (this.state.facebook == true) {
      this.props.history.push("/dashboard");
    }
  }

  signInWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    const auth = firebase.auth.getAuth();
    firebase.auth
      .signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        console.log("RESULT=> ", result);
        const user = result.user;

        // Apple credential
        const credential =
          firebase.auth.OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential =
          firebase.auth.OAuthProvider.credentialFromError(error);

        // ...
      });
  };

  signInWithFacebook = async () => {

    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {

        console.log('====================================');
        console.log("result-->", result);
        console.log('====================================');
        const uid = result?.user?.uid;
        const fname = result?.additionalUserInfo?.profile?.first_name;
        const lname = result?.additionalUserInfo?.profile?.last_name;
        const email =
          result?.user?.email
        const ref = firebase.firestore().collection("owners").doc(uid);


        if (!email) {
          alert("Please use an email to log in. Phone number login is not supported.");
          return;
        }

        ref.get().then((doc) => {
          if (!doc?.exists) {
            ref.set({
              fname: fname,
              lname: lname,
              email: email.toLowerCase(),
              stripeCustomerId: "gfgfd",
              bars: [],
              uid: uid,

            });
            this.props.history.push("/dashboard");
          } else {
            this.props.history.push("/dashboard");
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  signInWithGoogle = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const uid = result?.user?.uid;
        const fname = result?.additionalUserInfo?.profile?.given_name;
        const lname = result?.additionalUserInfo?.profile?.family_name;
        const email = result?.additionalUserInfo?.profile?.email;
        const ref = firebase.firestore().collection("owners").doc(uid);
        ref.get().then((doc) => {
          console.log("====================================");
          console.log("LoginDatat====>" + JSON.stringify(doc.data()));
          console.log("====================================");
          if (!doc.exists) {
            ref.set({
              fname: fname,
              lname: lname,
              email: email.toLowerCase(),
              stripeCustomerId: " ",
              bars: [],
              uid: uid,
            });
            this.props.history.push("/dashboard");
          } else {
            if (doc?.data()?.deleted == true) {
              ref.set({
                fname: doc?.data()?.fname,
                lname: doc?.data()?.lname,
                email: doc?.data()?.email.toLowerCase(),
                stripeCustomerId: doc?.data()?.stripeCustomerId,
                bars: doc?.data()?.bars,
                deleted: false,
                deletedAt: "",
                uid: uid,

              });

              if (doc?.data()?.bars?.length > 0) {
                const barIds = doc?.data()?.bars;
                barIds.map((barId) => {
                  return firebase
                    .firestore()
                    .collection("bars")
                    .doc(barId)
                    .update({
                      deleted: false,
                      deletedAt: "",
                    });
                });
              }
              this.props.history.push("/dashboard");
            } else {
              this.props.history.push("/dashboard");
            }
          }
        });

        // this.props.history.push("/dashboard");
      });
  };

  appleResponse = (response) => {
    if (!response.error) {
      axios
        .post("/auth", response)
        .then((res) => this.setState({ authResponse: res.data }))
        .catch((err) => console.log(err));
    }
  };

  render() {
    const { t } = this.props;

    const languages = [
      {
        value: "en",
        label: "English",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg",
      },
      {
        value: "de",
        label: "Germany",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg",
      },
      {
        value: "fr",
        label: "Français",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg",
      },
      {
        value: "es",
        label: "Spanish",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg",
      },
      {
        value: "sv",
        label: "Swedish",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg",
      },
    ];
    return (
      <>
        <div className="hero-banner">
          <div className="container-fluid">
            <div className="hero-banner-content">
              <div className="langauages-selector">
                {/* <select
                  value={i18n.language}
                  onChange={(e) => i18n.changeLanguage(e.target.value)}
                >
                  <option value="en">English</option>
                  <option value="de">Germany</option>
                  <option value="fr">Français</option>
                  <option value="es">Spanish</option>
                  <option value="sv">Swedish</option>
                </select> */}
                <Select
                  className="langauages"
                  value={languages.find((lang) => lang.value === i18n.language)}
                  onChange={(selectedOption) =>
                    i18n.changeLanguage(selectedOption.value)
                  }
                  options={languages}
                  getOptionLabel={(e) => (
                    <div className="text-img">
                      <img
                        src={e.image}
                        alt={e.label}
                        style={{ width: 17, height: 17, marginRight: 10 }}
                      />
                      {e.label}
                    </div>
                  )}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "#FFD700", // Gold color for background
                      cursor: "pointer", // Set cursor to pointer on hover
                      outline: "0", // Remove outline
                      border: "0", // Remo  ve border
                      fontFamily: "'proxima-nova', sans-serif", // Font family
                      fontSize: "16px", // Font size
                      fontWeight: "400", // Font weight
                      lineHeight: "19.49px", // Line height
                    }),
                    option: (provided) => ({
                      ...provided,
                      backgroundColor: "#FFD700", // Set background color of the
                      borderRight: "none",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#000", // Set color of the selected value
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "#FFD700", // Set background color of the menu dropdown
                      border: "none",
                    }),
                  }}
                />
              </div>

              <div className="imgs">
                <img src={logo} alt="logo" />
              </div>

              <div className="hero-content">
                <h2 className="title">
                  {t("landingNew.account_creation.title")}
                </h2>
                <p className="content">
                  {t("landingNew.account_creation.description1")}
                </p>
                {/* <p className="notes">
                  {t("landingNew.account_creation.description2")}
                </p> */}
              </div>

              <NavLink to="/create-account" className="buttons">
                <button className="btn">{t("landing.signUp")}</button>
              </NavLink>

              <div className="account-inquiry">
                {t("landing.alreadyMember")}
              </div>

              <div className="links">
                <NavLink to="/login" className="login-link">
                  {t("landing.logIn")}
                </NavLink>

                <NavLink to="/forgot-password" className="forget-link">
                  {t("landing.forgotPass")}
                </NavLink>
              </div>

              <div className="social_btn">
                <div className="social-imgs">
                  <img
                    src={googlelogo}
                    alt="Sign with Google"
                    onClick={() => this.signInWithGoogle()}
                  />
                </div>

                <div className="social-imgs">
                  <img
                    src={fblogo}
                    alt="Sign with Facebook"
                    onClick={() => this.signInWithFacebook()}
                  // onClick={() => this.signInWithApple()}
                  />
                </div>

                <div className="social-imgs">
                  <AppleLogin
                    clientId="com.services.barzone"
                    redirectURI="https://barzone-e3783.firebaseapp.com/__/auth/handler"
                    usePopup={true}
                    scope="name email"
                    onError={(error) =>
                      console.log("AppleSignInBtn onError error", error)
                    }
                    render={(renderProps) => (
                      <img
                        src={applelogo}
                        alt="Sign with Apple"
                        onClick={renderProps.onClick}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-zone">
          <img
            src={borderZone}
            alt="Sign with Google"
            onClick={() => this.signInWithGoogle()}
          />
        </div>

        <div className="content-boxview">
          <div className="container-fluid">
            <div className="row">
              <div className="content-box">
                <div className="content-wrap">
                  <h3 className="headingText">
                    {t("landingNew.choose_category.title")}
                  </h3>
                  <ul>
                    <li>{t("landingNew.choose_category.description1")}</li>
                    <li>{t("landingNew.choose_category.description2")}</li>
                  </ul>
                </div>
              </div>

              <div className="content-box">
                <div className="content-wrap">
                  <h3 className="headingText">
                    {t("landingNew.create_events.title")}
                  </h3>
                  <ul>
                    <li>{t("landingNew.create_events.description1")}</li>
                    <li>{t("landingNew.create_events.description2")}</li>
                    <li>{t("landingNew.create_events.description3")}</li>
                  </ul>
                </div>
              </div>

              <div className="content-box">
                <div className="content-wrap">
                  <h3 className="headingText">
                    {t("landingNew.link_social_media.title")}
                  </h3>
                  <ul>
                    <li>{t("landingNew.link_social_media.description1")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="features-app-content">
          <div className="container-fluid">
            <h3>{t("landingNew.users_features.title")}</h3>
            <p>{t("landingNew.users_features.description1")}</p>
            <div className="row">
              <div className="app-content-box content-box">
                <div className="row">
                  <div className="features">
                    <div className="content-wrap">
                      <div className="content-title">
                        <h4>{t("landingNew.users_features.key2")}</h4>
                        <p>{t("landingNew.users_features.description2")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="features">
                    <div className="content-wrap">
                      <div className="content-title">
                        <h4>{t("landingNew.users_features.key3")}</h4>
                        <p>{t("landingNew.users_features.description3")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="features">
                    <div className="content-wrap">
                      <div className="content-title">
                        <h4>{t("landingNew.users_features.key4")}</h4>
                        <p>{t("landingNew.users_features.description4")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="features">
                    <div className="content-wrap">
                      <div className="content-title">
                        <h4>{t("landingNew.users_features.key5")}</h4>
                        <p>{t("landingNew.users_features.description5")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="features">
                    <div className="content-wrap">
                      <div className="content-title">
                        <h4>{t("landingNew.users_features.key6")}</h4>
                        <p>{t("landingNew.users_features.description6")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="app-img-box content-box">
                <img src={mockup} />
              </div>
            </div>
          </div>
        </div>

        <footer id="site-footer" className="site-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="copy-right">
                Copyright © 2024. All Rights Reserved
              </div>
              <ul className="footer-links">
                <li>
                  <NavLink to="/privacy">{t("landing.privacy")}</NavLink>
                </li>

                <li>
                  <NavLink to="/aboutus">{t("landing.aboutUs")}</NavLink>
                </li>
                <li>
                  <a href={"mailto:support@bar-zone.com"}>
                    {t("landing.contactUs")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>

        <div className="storeButtonDiv">
          <a href="https://play.google.com/store/apps/details?id=barzone1.android">
            <img
              className="storeButtons"
              src={googlePlay}
              alt="google play"
            ></img>
          </a>
          <a href="https://apps.apple.com/se/app/barzone/id1511489019?l=en">
            <img className="storeButtons" src={appstore} alt="app store"></img>
          </a>
          <div className="socialButtonDiv">
            <a href="https://www.facebook.com/barzones/" target="_blank">
              <img className="social_link_btn" src={facebook} />
            </a>
            <a href="https://www.instagram.com/barzones/" target="_blank">
              <img className="social_link_btn" src={instagram} />
            </a>
            <a
              href="https://www.tiktok.com/@barzones?_t=8dW2w0EpP3r&_r=1"
              target="_blank"
            >
              <img className="social_link_btn" src={tiktok} />
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Landing);
